























































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ApiResponse } from '@/components/types';
import { REGISTER_SUPPORTER } from '@/modules/employee/store';
import { createSupporter } from '@/modules/employee/admin/models';
import { RegisterSupporter } from '@/modules/employee/types';
import SalutationSelect from '@/modules/conservatorship/components/SalutationSelect.vue';

const Employee = namespace('employee');

@Component({
  components: {
    SalutationSelect
  }
})
export default class AccountingForm extends Vue {
  @Employee.Action(REGISTER_SUPPORTER) register!: (params: RegisterSupporter) => Promise<ApiResponse>;

  error: any = null;
  loading: boolean = false;

  value: RegisterSupporter = createSupporter();

  reset(observer?: any) {
    this.error = null;
    this.value = createSupporter();

    observer && observer.reset();
  }

  cancel(observer?: any) {
    this.reset(observer);
    this.$emit('cancel');
  }

  back(observer: any) {
    this.reset(observer);
    this.$emit('back');
  }

  save() {
    this.error = null;

    return this.register(this.value);
  }
}

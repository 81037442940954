

















































































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FETCH_EMPLOYEE_LIST, GET_EMPLOYEE_LIST } from '../../store';
import { FETCH_CONSERVATOR_LIST, GET_CONSERVATOR_BY_ID } from '@/modules/conservator/store';
import { Employee } from '../../types';
import { Conservator } from '@/modules/conservator/types';
import { ApiResponse, Pagination } from '@/components/types';
import ErrorAlert from '@/components/ErrorAlert.vue';
import CreateEmployeeDialog from '../components/CreateEmployeeDialog.vue';
import UpdateCoreDataDialog from '../components/UpdateCoreDataDialog.vue';
import ChangePositionToConservatorDialog from '../components/ChangePositionToConservatorDialog.vue';
import UpdateHourlyRateDialog from '@/modules/conservator/components/UpdateHourlyRateDialog.vue';
import AppTable from '@/components/AppTable.vue';
import ListSearchDialog from '@/components/ListSearchDialog.vue';
import DismissConservatorDialog from "@/modules/employee/admin/components/DismissConservatorDialog.vue";

const Employee = namespace('employee');
const ConservatorStore = namespace('conservator');

@Component({
  components: {
    DismissConservatorDialog,
    ListSearchDialog,
    AppTable,
    UpdateHourlyRateDialog,
    ChangePositionToConservatorDialog,
    UpdateCoreDataDialog,
    CreateEmployeeDialog,
    ErrorAlert
  }
})
export default class Overview extends Vue {
  @ConservatorStore.Getter(GET_CONSERVATOR_BY_ID) findConservatorById!: (id: string) => Conservator | undefined;
  @ConservatorStore.Action(FETCH_CONSERVATOR_LIST) fetchConservators!: () => Promise<ApiResponse<Conservator[]>>;

  @Employee.Getter(GET_EMPLOYEE_LIST) list!: Employee[];
  @Employee.Action(FETCH_EMPLOYEE_LIST) fetchEmployees!: () => Promise<ApiResponse<Employee[]>>;

  search: string = '';
  error: any = null;
  loading: boolean = false;
  coreData: Employee | null = null;
  hourlyRate: Conservator | null = null;
  changePosition: Employee | null = null;
  dismissConservator: Employee | null = null;

  pagination: Pagination = {
    sortBy: ['name'],
    itemsPerPage: -1
  };

  get items() {
    return this.list.map((employee) => ({
      ...employee,
      positionTrans: this.$i18n.t(`employee.position.${employee.position}`)
    }));
  }

  get headers() {
    return [
      { align: 'left', text: this.$i18n.t('common.name'), value: 'name' },
      { align: 'left', text: this.$i18n.t('common.email'), value: 'email' },
      { align: 'left', text: this.$i18n.t('common.position'), value: 'positionTrans' },
      { align: 'left', text: this.$i18n.t('common.actions'), value: null, sortable: false }
    ];
  }

  async created() {
    this.loading = true;
    this.error = null;

    const { error } = await this.fetchEmployees();

    this.loading = false;

    if (error) {
      this.error = error;
    }

    return this.fetchConservators();
  }
}

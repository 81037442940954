




























import { Component } from 'vue-property-decorator';
import { AuthorizationMixin } from '@/views/mixin';

@Component
export default class RoleSelectForm extends AuthorizationMixin {
  options = [
    { component: 'conservator-form', label: 'Betreuer' },
    { component: 'administrator-form', label: 'Administrator' },
    { component: 'junior-administrator-form', label: 'Verwaltung' },
    { component: 'supporter-form', label: 'Bürokraft' },
    { component: 'accounting-form', label: 'Steuerbüro' },
    ...(this.isSystemAdmin() ? [{ component: 'system-administrator-form', label: 'System-Administrator' }] : []),
    ...(this.isSystemAdmin() ? [{ component: 'independent-conservator-form', label: 'Selbständiger Betreuer' }] : [])
  ];
}




























































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ApiResponse } from '@/components/types';
import { REGISTER_CONSERVATOR } from '@/modules/employee/store';
import { createConservator } from '@/modules/employee/admin/models';
import { RegisterConservator } from '@/modules/employee/types';
import SalutationSelect from '@/modules/conservatorship/components/SalutationSelect.vue';

const Employee = namespace('employee');

@Component({
  components: {
    SalutationSelect
  }
})
export default class ConservatorForm extends Vue {
  @Employee.Action(REGISTER_CONSERVATOR) register!: (params: RegisterConservator) => Promise<ApiResponse>;

  error: any = null;
  loading: boolean = false;

  value: RegisterConservator = createConservator();

  reset(observer?: any) {
    this.error = null;
    this.value = createConservator();
    observer && observer.reset();
  }

  cancel(observer?: any) {
    this.reset(observer);
    this.$emit('cancel');
  }

  back(observer: any) {
    this.reset(observer);
    this.$emit('back');
  }

  save() {
    this.error = null;

    return this.register(this.value);
  }
}

import {
  DismissConservator,
  Employee, RegisterAccounting,
  RegisterAdministrator,
  RegisterConservator,
  RegisterIndepenentConservator,
  RegisterJuniorAdministrator, RegisterSupporter,
  RegisterSystemAdministrator,
  UpdateCoreData
} from '@/modules/employee/types';
import uuid from 'uuid/v4';
import { Position } from '../types';

export const createIndependentConservator = (): RegisterIndepenentConservator => ({
  employee_id: uuid(),
  salutation: '',
  full_name: '',
  email: '',
  hourly_rate: 0,
  password: '',
  position: Position.INDEPENDENT_CONSERVATOR
});

export const createConservator = (): RegisterConservator => ({
  employee_id: uuid(),
  salutation: '',
  full_name: '',
  email: '',
  hourly_rate: 0,
  password: '',
  position: Position.CONSERVATOR
});

export const createAdministrator = (): RegisterAdministrator => ({
  employee_id: uuid(),
  salutation: '',
  full_name: '',
  email: '',
  password: '',
  position: Position.ADMINISTRATION
});

export const createAccounting = (): RegisterAccounting => ({
  employee_id: uuid(),
  salutation: '',
  full_name: '',
  email: '',
  password: '',
  position: Position.ACCOUNTING
});

export const createSupporter = (): RegisterSupporter => ({
  employee_id: uuid(),
  salutation: '',
  full_name: '',
  email: '',
  password: '',
  position: Position.SUPPORTER
});

export const createJuniorAdministrator = (): RegisterJuniorAdministrator => ({
  employee_id: uuid(),
  salutation: '',
  full_name: '',
  email: '',
  password: '',
  position: Position.JUNIOR_ADMINISTRATION
});

export const createSystemAdministrator = (): RegisterSystemAdministrator => ({
  employee_id: uuid(),
  salutation: '',
  full_name: '',
  email: '',
  password: '',
  position: Position.SYSTEM_ADMINISTRATION
});

export const MapViewToUpdateCoreData = (employee: Employee): UpdateCoreData => ({
  employee_id: employee.id,
  salutation: employee.salutation,
  full_name: employee.name,
  email: employee.email
});

export const MapViewToDismissConservator = (employee: Employee): DismissConservator => ({
  employee_id: employee.id,
  date: ''
});

























































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ApiResponse } from '@/components/types';
import { REGISTER_ADMINISTRATOR } from '@/modules/employee/store';
import { createAdministrator } from '@/modules/employee/admin/models';
import { RegisterAdministrator } from '@/modules/employee/types';
import SalutationSelect from '@/modules/conservatorship/components/SalutationSelect.vue';

const Employee = namespace('employee');

@Component({
  components: {
    SalutationSelect
  }
})
export default class AdministratorForm extends Vue {
  @Employee.Action(REGISTER_ADMINISTRATOR) register!: (params: RegisterAdministrator) => Promise<ApiResponse>;

  error: any = null;
  loading: boolean = false;

  value: RegisterAdministrator = createAdministrator();

  reset(observer?: any) {
    this.error = null;
    this.value = createAdministrator();

    observer && observer.reset();
  }

  cancel(observer?: any) {
    this.reset(observer);
    this.$emit('cancel');
  }

  back(observer: any) {
    this.reset(observer);
    this.$emit('back');
  }

  save() {
    this.error = null;

    return this.register(this.value);
  }
}

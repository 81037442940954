


















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ApiResponse } from '@/components/types';
import SalutationSelect from '@/modules/conservatorship/components/SalutationSelect.vue';
import { Employee, UpdateCoreData } from '@/modules/employee/types';
import { UPDATE_CORE_DATA } from '@/modules/employee/store';
import { MapViewToUpdateCoreData } from '@/modules/employee/admin/models';

const Employee = namespace('employee');

@Component({
  components: {
    SalutationSelect
  }
})
export default class UpdateCoreDataDialog extends Vue {
  @Prop() value!: Employee | null;

  @Employee.Action(UPDATE_CORE_DATA) updateCoreData!: (command: UpdateCoreData) => Promise<ApiResponse>;

  error: any = null;
  dialog: boolean = false;

  employee: UpdateCoreData = {
    employee_id: '',
    salutation: '',
    full_name: '',
    email: ''
  };

  @Watch('dialog')
  watchDialogClosed(dialog: boolean) {
    if (dialog) return;

    setTimeout(() => {
      this.employee = {
        employee_id: '',
        salutation: '',
        full_name: '',
        email: ''
      };

      this.error = null;
      this.$emit('input', null);
      // @ts-ignore
      this.$refs.form.reset();
    }, 200);
  }

  @Watch('value')
  watchTermSet(employee: Employee) {
    if (!employee) return;

    this.init();

    setTimeout(() => {
      this.dialog = true;
    }, 200);
  }

  init() {
    if (!this.value) return;

    this.employee = MapViewToUpdateCoreData(this.value);
  }

  async save(): Promise<ApiResponse> {
    this.error = null;

    return this.updateCoreData(this.employee);
  }
}



















import { Component, Vue, Watch } from 'vue-property-decorator';
import RoleSelectForm from './CreateEmployeeDialog/RoleSelectForm.vue';
import ConservatorForm from './CreateEmployeeDialog/ConservatorForm.vue';
import AdministratorForm from './CreateEmployeeDialog/AdministratorForm.vue';
import JuniorAdministratorForm from './CreateEmployeeDialog/JuniorAdministratorForm.vue';
import SystemAdministratorForm from './CreateEmployeeDialog/SystemAdministratorForm.vue';
import IndependentConservatorForm from './CreateEmployeeDialog/IndependentConservatorForm.vue';
import AccountingForm from './CreateEmployeeDialog/AccountingForm.vue';
import SupporterForm from './CreateEmployeeDialog/SupporterForm.vue';

@Component({
  components: {
    RoleSelectForm,
    ConservatorForm,
    AdministratorForm,
    JuniorAdministratorForm,
    SystemAdministratorForm,
    IndependentConservatorForm,
    AccountingForm,
    SupporterForm
  }
})
export default class CreateEmployeeDialog extends Vue {
  dialog: boolean = false;
  componentName = 'role-select-form';

  @Watch('dialog')
  onChange(dialog: boolean) {
    if (!dialog) {
      this.componentName = 'role-select-form';
    }
  }
}






































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ApiResponse } from '@/components/types';
import SalutationSelect from '@/modules/conservatorship/components/SalutationSelect.vue';
import { DismissConservator, Employee, Position } from '@/modules/employee/types';
import { DISMISS_CONSERVATOR } from '@/modules/employee/store';
import { MapViewToDismissConservator } from '@/modules/employee/admin/models';
import DatePicker from "@/components/form/DatePicker.vue";

const Employee = namespace('employee');

@Component({
  components: {
    DatePicker,
    SalutationSelect
  }
})
export default class UpdateCoreDataDialog extends Vue {
  @Prop() value!: Employee | null;

  @Employee.Action(DISMISS_CONSERVATOR) dismiss!: (command: DismissConservator) => Promise<ApiResponse>;

  error: any = null;
  dialog: boolean = false;

  employee: DismissConservator = {
    employee_id: '',
    date: ''
  };

  @Watch('dialog')
  watchDialogClosed(dialog: boolean) {
    if (dialog) return;

    setTimeout(() => {
      this.employee = {
        employee_id: '',
        date: ''
      };

      this.error = null;
      this.$emit('input', null);
      // @ts-ignore
      this.$refs.form.reset();
    }, 200);
  }

  @Watch('value')
  watchTermSet(employee: Employee) {
    if (!employee) return;

    this.init();

    setTimeout(() => {
      this.dialog = true;
    }, 200);
  }

  init() {
    if (!this.value) return;

    this.employee = MapViewToDismissConservator(this.value);
  }

  async save(): Promise<ApiResponse> {
    this.error = null;

    if (this.value && this.value.position !== Position.CONSERVATOR) {
      return {}
    }

    return this.dismiss(this.employee);
  }
}
